export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "out-of-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new version of the app is available."])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, the app is currently experiencing some issues. We are working to fix it."])}
      },
      "fr": {
        "out-of-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle version de l'application est disponible."])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, l'application rencontre présentement des problèmes. Nous travaillons à régler la situation."])}
      }
    }
  })
}
