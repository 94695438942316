export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "clear-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear selected date"])},
        "close-time-picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en-US"])},
        "open-time-picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
      },
      "fr": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "clear-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer la date sélectionnée"])},
        "close-time-picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le sélecteur d'heure"])},
        "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr-CA"])},
        "open-time-picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le sélecteur d'heure"])},
        "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])}
      }
    }
  })
}
