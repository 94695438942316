import script from "./date-picker.vue?vue&type=script&setup=true&lang=js"
export * from "./date-picker.vue?vue&type=script&setup=true&lang=js"

import "./date-picker.vue?vue&type=style&index=0&id=0319d7f3&lang=postcss"
/* custom blocks */
import block0 from "./date-picker.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__