export const COLOR_0 = "COLOR_0";
export const COLOR_1 = "COLOR_1";
export const COLOR_2 = "COLOR_2";
export const COLOR_3 = "COLOR_3";
export const COLOR_4 = "COLOR_4";
export const COLOR_5 = "COLOR_5";
export const COLOR_6 = "COLOR_6";
export const COLOR_7 = "COLOR_7";
export const COLOR_8 = "COLOR_8";
export const COLOR_9 = "COLOR_9";
export const COLOR_10 = "COLOR_10";
