export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "close-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close sidebar"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomat logo"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help and support"])},
        "support-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.notion.so/allotomat/Tomat-Support-Center-fbf571e40909463db20b3a0160a65b3a"])}
      },
      "fr": {
        "close-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le panneau"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de Tomat"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide et support"])},
        "support-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://allotomat.notion.site/"])}
      }
    }
  })
}
