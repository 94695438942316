export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "open-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open sidebar"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomat logo"])}
      },
      "fr": {
        "open-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le panneau"])},
        "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de Tomat"])}
      }
    }
  })
}
