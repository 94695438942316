export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "gift-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift card"])},
        "expiration-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expires on ", _interpolate(_named("date"))])},
        "never-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never expires"])}
      },
      "fr": {
        "gift-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte-cadeau"])},
        "expiration-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expire le ", _interpolate(_named("date"))])},
        "never-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'expire jamais"])}
      }
    }
  })
}
