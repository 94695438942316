export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cancel-button-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "confirm-button-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
      },
      "fr": {
        "cancel-button-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "confirm-button-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
      }
    }
  })
}
