export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "open-product-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See balance by product group"])}
      },
      "fr": {
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "open-product-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le solde par groupe de produits"])}
      }
    }
  })
}
