export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "image-prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop or choose an image"])},
        "delete-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete image"])}
      },
      "fr": {
        "image-prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer ou choisir une image"])},
        "delete-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'image"])}
      }
    }
  })
}
