export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by email, ID, name..."])},
        "placeholder-anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by ID..."])},
        "btn-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "fr": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher par courriel, ID, nom..."])},
        "placeholder-anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher par ID..."])},
        "btn-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
      }
    }
  })
}
