export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to home page"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])}
      },
      "fr": {
        "go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])}
      }
    }
  })
}
