export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© ", _interpolate(_named("year")), " — Collectif Récolte"])},
        "realization-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A realization<br/>of Sigmund"])},
        "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
        "terms-of-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
        "collectif-recolte-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://recolte.ca/home/"])}
      },
      "fr": {
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© ", _interpolate(_named("year")), " — Collectif Récolte"])},
        "realization-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une réalisation<br/>de Sigmund"])},
        "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
        "terms-of-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
        "collectif-recolte-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://recolte.ca/accueil/"])}
      }
    }
  })
}
