export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "account-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
        "admin-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User administration"])},
        "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "user-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account options"])}
      },
      "fr": {
        "account-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages du compte"])},
        "admin-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
        "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
        "user-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du compte utilisateur"])}
      }
    }
  })
}
